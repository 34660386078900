// extracted by mini-css-extract-plugin
export var about = "about-module--about--d766s";
export var descriptionContainer = "about-module--description-container--cbLPr";
export var descriptionHeader = "about-module--description-header--26ulh";
export var descriptionP = "about-module--description-p--Zf+5Y";
export var genHeader = "about-module--gen-header--hVNia";
export var h1 = "about-module--h-1--4OArv";
export var h2 = "about-module--h-2--BK1qf";
export var h3 = "about-module--h-3--YmM3I";
export var howToButton = "about-module--how-to-button--EoehY";
export var indicatorContainer = "about-module--indicator-container--NgN40";
export var locationText = "about-module--location-text--zMKzg";
export var originButton = "about-module--origin-button--qm8TK";
export var originCta = "about-module--origin-cta--sFNTU";
export var p1 = "about-module--p-1--Q2VYR";
export var p2 = "about-module--p-2--Kysly";
export var p3 = "about-module--p-3--yDq1p";
export var seen = "about-module--seen--QCwi2";
export var slideBody = "about-module--slide-body--ZAwCS";
export var slideDescription = "about-module--slide-description--4n-bo";
export var slideLocation = "about-module--slide-location--DmXiI";
export var slideshowContainer = "about-module--slideshow-container--5+8vM";
export var widen = "about-module--widen--pHqV3";