// extracted by mini-css-extract-plugin
export var archive = "archive-module--archive--Sr4Fp";
export var description = "archive-module--description--8s1jH";
export var exit = "archive-module--exit--Zhqpg";
export var genHeader = "archive-module--gen-header--UADyp";
export var h1 = "archive-module--h-1--Tb-Gi";
export var h2 = "archive-module--h-2--weB-M";
export var h3 = "archive-module--h-3--VZ2XZ";
export var header = "archive-module--header--EyMeQ";
export var imageContainer = "archive-module--image-container--zaStk";
export var p1 = "archive-module--p-1--miqrN";
export var p2 = "archive-module--p-2--VyQS+";
export var p3 = "archive-module--p-3--v3vZN";
export var project = "archive-module--project--FbkUl";
export var rev = "archive-module--rev--6Vd7Z";
export var row = "archive-module--row--9TwV9";
export var subheader = "archive-module--subheader--3emI6";
export var textContainer = "archive-module--text-container--ZwJrx";
export var time = "archive-module--time--LxDX+";
export var title = "archive-module--title--KSp3Q";