// extracted by mini-css-extract-plugin
export var genHeader = "home-module--gen-header--0uske";
export var h1 = "home-module--h-1--P-vMC";
export var h2 = "home-module--h-2--zZ15r";
export var h3 = "home-module--h-3--oQz0C";
export var heroHeader = "home-module--hero-header--CnRBG";
export var heroImage = "home-module--hero-image--aB3HV";
export var heroSubheader = "home-module--hero-subheader--tjf+W";
export var heroTextContainer = "home-module--hero-text-container--8YeLo";
export var p1 = "home-module--p-1--L80G3";
export var p2 = "home-module--p-2--vBg6D";
export var p3 = "home-module--p-3--ZU+Cb";
export var scrollButton = "home-module--scroll-button--mLRMh";
export var scrollPrompt = "home-module--scroll-prompt--+d563";