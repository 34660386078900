// extracted by mini-css-extract-plugin
export var activity = "contact-module--activity--tH7LK";
export var activityDescription = "contact-module--activity-description--GPEY3";
export var activityHeader = "contact-module--activity-header--5h2yg";
export var activityIcon = "contact-module--activity-icon--v0BIc";
export var activityText = "contact-module--activity-text--I1SDb";
export var attribution = "contact-module--attribution--j1FgT";
export var button = "contact-module--button--vEcUB";
export var buttonRow = "contact-module--button-row--i6T3N";
export var campusContainer = "contact-module--campus-container--mneKD";
export var container = "contact-module--container--q0JeS";
export var content = "contact-module--content--CP3GX";
export var emailButton = "contact-module--email-button--sV+zI";
export var footHeader = "contact-module--foot-header--qBn2U";
export var footer = "contact-module--footer--hW5Oy";
export var footerLinks = "contact-module--footer-links--kZoRV";
export var genHeader = "contact-module--gen-header--blEyQ";
export var h1 = "contact-module--h-1--bJvoF";
export var h2 = "contact-module--h-2--2d7gk";
export var h3 = "contact-module--h-3--AEPD3";
export var header = "contact-module--header--gppFC";
export var linkedInButton = "contact-module--linked-in-button--mLc8n";
export var merge = "contact-module--merge--2mfEL";
export var p1 = "contact-module--p-1--Mr360";
export var p2 = "contact-module--p-2--VUE8b";
export var p3 = "contact-module--p-3--MW5-l";
export var resumeButton = "contact-module--resume-button--2RUU6";
export var separator = "contact-module--separator--axRSX";
export var subheader = "contact-module--subheader--xJThR";